html, body {
  height: 100%;
}

// body {
//   display: flex;
//   flex-direction: column;
// }
#container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

header.header {
  display: flex;
  justify-content: space-between;
  // padding: 40px;
}

main.main {
  flex: 1 0 auto;
  display: flex;
}


// HEADER

.logo {
  padding: 25px 40px;
}

nav ul {
  display: flex;
  padding: 20px;
  li {
    margin: 20px;
  }
}

// MAIN

.left {
  min-width: 200px;
  width: calc(20% - 80px);
  padding: 40px;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  .contact {
    display: flex;
    flex-direction: column;
  }
}
.right {
  width: calc(80% - 160px);
  padding: 20px 80px;
}

.btns {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  .button {
    margin: 0 10px 10px 0;
  }
}

.brand-story .left, .brand-design .left, .pitch .left {
  justify-content: space-between;
}

.m-submenu {
  background-color: black;
  // position: fixed;
  color: white;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  cursor: pointer;
  z-index: 12345;
  &:after {
    content: '';
    position: absolute;
    right: 60px;
    top: 25px;
    background-image: url(/img/arrow.svg);
    transform-origin: center center;
    width: 15px;
    height: 15px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform:rotate(90deg);
  }
}

.sections {
  margin-bottom: 90px;
  a {
    display: block;
  }
  .section-child {
    margin-left: 10px;
  }
  .main-section > li > .child-section {
    display: none;
    overflow: hidden;
}

.section-title li:target > .child-section {
    display: block;
  }
}

.right {
  section {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 200px;
    }
  }
  .section-title-b {
    padding-top: 40px;
    &:first-child {
      padding-top: 20px;
    }
  }
  .sub-section {
    padding-top: 1px;
    margin-bottom: 40px;
  }

  .txt {
    ul {
      margin: 20px 0;
      li {
        margin: 0 20px;
      }
    }
  }

  .tib {
    .tib-intro {
      display: flex;
      h1, .txt {
        width: 50%;
      }
      .txt {
        margin-top: 10px;
      }
    }

  }
  .imgs, .blocks {
    display: flex;
    justify-content: space-between;

    .block {
      width: 100%;
    }

    .img, .block {
      margin-left: 30px;
      &:first-child {
        margin-left: 0;
      }
    }
    .block .img {
      margin-left: 0;
    }
    .caption {
      text-align: right;
    }

  }
  .imgs {
    margin-top: 20px;
  }
  .download {
    margin-top: -20px;
    img {
      max-width: 16px;
      display: inline-block;
      margin-right: 10px;
      margin-top: -5px;
    }
    &.link img {
      margin-top: -3px;
      margin-right: 5px;
    }
  }

  .cb {
    .colors {
      display: flex;
      flex-wrap: wrap;
    }

    .color {
      margin: 0 20px 20px 0;
      width: 200px;
    }
    .circle {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin-bottom: 20px;
      &.stroke {
        border: 1px solid #c4c4c4;
      }
    }
  }

  .vid-block {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: white;
      top: calc(50% - 50px);
      left: 0;
      right: 0;
      margin: 0 auto;
      cursor: pointer;
      pointer-events: none;
    }
    &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 30px solid $maincolor;
      top: calc(50% - 20px);
      left: 7px;
      right: 0;
      margin: 0 auto;
      cursor: pointer;
      pointer-events: none;
    }
    &.playing {
      &:before, &:after {
        display: none;
      }
    }
  }

  video {
    width: 100%;
    max-height: 100%;
  }
}

.brand-design, .pitch {
  .vid-block {margin-top: 20px;}
}

.login {

  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  align-self: center;
  text-align: center;
  h1 {
    margin-bottom: 10px;
  }
  p.big {
    margin-top: 20px;
  }
  form {
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 100px;
    input {
      width: 80%;
      border: none;
      padding: 20px;
    }
    label {
      width: 80%;
      text-align: left;
      margin-left: -30px;
      margin-bottom: 10px;
      display: inline-block;
    }
  }
  form .group {
    text-align: left;
  }
}

figure {
  margin: 0;
}

// PITCH SPECIFIC
.pitch {
  .highlight {
    background-color: $yellow;
    padding: 5px 40px;
    margin-top: 20px;

    ul {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
    }
  }
  .member {
    display: flex;
    .info {
      width: 50%;
    }
    .img {
      padding-top: 30px;
      margin-right: 30px;
      width: calc(50% - 30px);
    }
  }
  .dwnld-block {
    margin: 40px 0;
  }
  .intro, .member {
    margin-bottom: 60px;
  }
  table {
    p {
      margin: 0;
      padding: 0;
    }
    td {
      vertical-align: top;
    }
    strong {
      margin-top: 20px;
      display: block;
    }
  }

  .reference {
    .img, .vid {
      margin-bottom: 20px;
    }
  }
}

@media (min-width:45em) and (min-height: 300px) {
  .sections {
    position: sticky;
    top: 30px;
  }
}


@media only screen and (max-width: 60em) {

  .right {

    .tib {
      .tib-intro {
        flex-direction: column;
        h1, .txt {
          width: 100%;
        }
      }
    }
    .blocks, .imgs {
      flex-direction: column;
      .block, .img {
        margin: 0;
      }
    }
  }

}

@media only screen and (max-width: 55em) {

  .brand-design .m-submenu, .pitch .m-submenu {
    display: block;
    padding: 20px;
    top: -100px;
    position: fixed;
  }

  .brand-design.scrolling, .pitch-design.scrolling {
    .m-submenu {
      top: 0;
      margin-top:0;
    }
  }

  header, main.main {
    flex-direction: column;
  }
  .logo {
    padding: 20px 60px 20px 20px;
  }
  nav ul {
    padding: 0 0;
  }
  .left, .right {
    width: calc(100% - 40px);
    margin: 0;
    padding: 20px;
  }

  .left, .m-submenu, .m-submenu:after  {
    transition: all 0.3s ;
    transition-timing-function: cubic-bezier('{"ease":".25,.1,.25,1","linear":"0,0,1,1","ease-in":".42,0,1,1","ease-out":"0,0,.58,1","ease-in-out":".42,0,.58,1"}');
  }
  .left {

    position: fixed;
    top: -100%;

    .sections {
      margin-bottom: 10px;
      // position: fixed;
      top: 0;
      background-color: $maincolor;
      width: 100%;
      left: 0;
      padding-left: 20px;
      a {
        // display: none;
        // &.current {
        //   display: block;
        // }
      }
    }
    .contact {
      display: none;
    }
  }

  .submenuopen {
    .m-submenu {
      position: fixed;
      top: 0;

      &:after {
        transform:rotate(-90deg);
      }
    }
    .left {
      top: 50px;
    }
  }

  .pitch {
    .member {
      flex-direction: column;
      .info, .img {
        width: 100%;
        margin: 0;
      }
    }
  }

}