/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The font file(s) should be placed relative to the regular CSS file.
 *
 */

/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 *
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media.
 *
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * dift.be (monthly pageviews: <50K)
 *
 */

/* complete */

@font-face {
  font-family: "CircularXXWeb-Bold";
  src: url("/fonts/CircularXXWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Bold";
  src: url("/fonts/CircularXXWeb-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-Medium";
  src: url("/fonts/CircularXXWeb-Medium.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Medium";
  src: url("/fonts/CircularXXWeb-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-MediumItalic";
  src: url("/fonts/CircularXXWeb-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-MediumItalic";
  src: url("/fonts/CircularXXWeb-MediumItalic.woff2") format("woff2");
}


/* subset */

@font-face {
  font-family: "CircularXXSub-Bold";
  src: url("/fonts/CircularXXSub-Bold.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-Bold";
  src: url("/fonts/CircularXXSub-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-Medium";
  src: url("/fonts/CircularXXSub-Medium.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-Medium";
  src: url("/fonts/CircularXXSub-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-MediumItalic";
  src: url("/fonts/CircularXXSub-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-MediumItalic";
  src: url("/fonts/CircularXXSub-MediumItalic.woff2") format("woff2");
}

/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Metafora Extra Bold by Dirtyline Studio
 * URL: https://www.myfonts.com/products/extra-bold-metafora-3382
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * © 2023 MyFonts Inc. */



@font-face {
  font-family: "MetaforaExtraBold";
  src: url('/fonts/MetaforaExtraBold/font.woff2') format('woff2'), url('/fonts/MetaforaExtraBold/font.woff') format('woff');
}


html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: CircularXXWeb-Medium, Helvetica Neue, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

h1, .logo, nav a, .section-title-b {
  font-family: CircularXXWeb-Bold, Helvetica Neue, sans-serif;
  // font-weight: 700;
  font-style: normal;
}

h1 {
  line-height: 2.5rem;
}

h1,h2 {
  font-weight: normal;
}
.login .big {
  font-size: 2.5rem;
}

.logo, .section-title-b, .reference h2 {
  font-size: 3.25rem;
  line-height: 3.25rem;
}


.big {
  font-size: 2rem;
  line-height: 2.25rem;
}

.sub-section .intro .txt, nav a, .reference .txt {
  font-size: 1.75rem;
  line-height: 2rem;
}
.section-child, .caption {
  font-size: 1rem;
}
.download {
  font-size: 0.85rem;
}

.member {
  h2 {
    font-size: 2.5rem;
  }
}