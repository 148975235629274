.m-menu {

  position: absolute;
  top:20px;
  right:10px;
  display: none;

  .mm {
    border:none;
    background:none;
    cursor: pointer;
    position: relative;
    display: block;
    width:50px;
    height:50px;
    outline:none;
    padding: 0; // ios has a bug otherwise

    &:before, &:after {
      position: absolute;
      display: block;
      content:'';
      width:14px;
      height: 2px;
      left: 18px;
      top: 19px;
      background-color:$maincolor;
      transition: all 0.15s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
    }
    &:after {
      top: 27px;
    }

  }

}

.m-menu-open {
  .m-menu .mm {
    &:before {
      top:24px;
      transform:rotate(-45deg);
    }
    &:after {
      top:24px;
      transform:rotate(45deg);
    }
  }

}

@media only screen and (max-width: 35em) {
  .m-menu {
    display: block;
  }

  .m-menu-open {
    .nav {
      display: block;
      width: 100%;

      ul {
        flex-direction: column;
        li {
          margin: 10px 0;
          padding: 0;
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          display: block;
          text-align: center;
          padding-bottom: 10px;

        }

      }
    }
  }

  .nav {
    display: none;
  }

}