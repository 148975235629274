/* Put your global styles here */
@import 'normalize';
@import 'boilerplate';
@import 'variables';
@import 'typo';
@import 'layout';
@import 'nav';

body {
  background-color: $bgcolor;
  color: $maincolor;
}

a {
  color: $maincolor;
  text-decoration: none;
  // display: inline-block;
}

ul,
li {
  margin:0;
  padding:0;
}

nav li, .sections li {
  list-style: none;
}

nav a {
  border-bottom: 5px solid transparent;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.active, a:hover {
  color: $highlightcolor;
}
.button {
  background-color: $maincolor;
  color: white;
  text-transform: uppercase;
  padding: 15px 30px;
  border: 1px solid $maincolor;
  &:hover {
    background-color: transparent;
  }
}

header.header {
  background-color: $bgcolor;
}

.left {
  background-color: $maincolor;
  color: white;
  a {
    color: white;
    margin: 5px 0;
  }
}
.right {
  background-color: $grey;
}

.download, .cb .info {
  text-transform: uppercase;
}

.txt a {
  text-decoration: underline;
}

// pitch specific
.pitch {
  background-color: $yellow;
  nav a:hover, nav a.active {
      border-bottom: 5px solid $yellow;
  }
  a.current, .sections a:hover {
      color: $yellow;
  }
}

